import { Feature, Point } from 'geojson'
import { ITreeList } from '@/widgets/MenuPanel/ui/TreeView/methods/TreeList'

export function handleObjectNodeClick(this: any, clickedObject: ITreeList) {
    console.log('clickedAgnksNode', this)
    if (!clickedObject.id || !clickedObject.geometry) return

    // const regionBbox: BBox = bbox(region.geometry)
    this.map.jumpTo({
        center: clickedObject.geometry,
        zoom: 16,
    })

    return clickedObject
}
// handleObjectNodeClick.chain = () => (callback: (args: typeof handleObjectNodeClick.arguments) => any) => callback(handleObjectNodeClick.arguments);

// export default handleObjectNodeClick
